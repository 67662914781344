import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import YouTube from '../components/YouTube';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        noPic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "date": "2017-03-13",
  "title": "Hidden Devtools Gem for Working with Media Queries",
  "description": "Media queries are amazing. Chrome makes working with them even better",
  "cover": "/img/cover/responsive-cover.png",
  "noPic": true,
  "color": "#38cfff"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Chrome dev tools has a hidden little gem that makes working with `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries/Using_media_queries"
      }}>{`CSS media queries`}</a>{` a lot nicer. It's a tool that lets you visualize, jump to, and locate all the media queries in your project. I discovered it while working on the `}<a parentName="p" {...{
        "href": "//flexboxzombies.com"
      }}>{`flexbox zombies`}</a>{` responsive gameboard. `}</p>
    <h2>{`Activate Media Query Tool`}</h2>
    <p>{`The tool isn't enabled by default. To enable it: `}</p>
    <ul>
      <li parentName="ul">{`go into responsive design mode`}</li>
      <li parentName="ul">{`click the three dots menu in the top right of the screen`}</li>
      <li parentName="ul">{`click "show media queries"`}</li>
    </ul>
    <YouTube id="dPU5xGty0tE" mdxType="YouTube" />
    <h2>{`Switching Media Queries`}</h2>
    <p>{`Once enabled, this tool lets you view a visualization of all the media queries in your project. It also highlights the one(s) that are currently in effect. An awesome thing about this is it lets you quickly jump back and forth between your queries by clicking on them. This can save a lot of time compared to constantly resizing the responsive controls manually.`}</p>
    <YouTube id="S9OI1gpkrJU" mdxType="YouTube" />
    <h2>{`Locating Media Queries in Code`}</h2>
    <p>{`Often when working with media queries you'll come across something you need to tweak to make things look nicer on various screen resolutions. This thing's got us covered again! We can easily jump to the media query source code:`}</p>
    <ul>
      <li parentName="ul">{`right click the media query visualization you want to change`}</li>
      <li parentName="ul">{`click "Reveal in Source Code"`}</li>
      <li parentName="ul">{`select the source location (there may be more than one place that same query is used) and the source panel opens straight to it`}</li>
    </ul>
    <YouTube id="NbGdU1tHu-s" mdxType="YouTube" />
    <p>{`So that's it! I love finding little gems like this in the devtools that make devlife a little bit better. Enjoy!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      